"use strict";

// Global Vars
var globalVar = {
  // off canvas
  offCanvasEle: '#offCanvas',
  offCanvasOpts: {
    autoFocus: false
  },
  // sticky var
  stickyEle: '.sticky-top',
  stickyScrollY: 40,
  stickyClass: 'state-sticky--active',
  // carousel var
  carouselEle: '.module-carousel__content',
  carouselItem: '.module-carousel__item',
  carouselInfobox: '.module-infobox__slideritem',
  formSumitted: false
}; 

// Start off canvas element

// TODO: Fix offCanvas
var offCanvas = null; // $(globalVar.offCanvasEle).length ? new Foundation.OffCanvas($(globalVar.offCanvasEle), globalVar.offCanvasOpts) : null; // add and remove sticky class to animate navigation

var stickyNav = $(globalVar.stickyEle);

var stickFn = function stickFn(e) {
  if (window.pageYOffset > globalVar.stickyScrollY) {
    stickyNav.addClass(globalVar.stickyClass);
  } else {
    stickyNav.removeClass(globalVar.stickyClass);
  }
};

var uploadFiles = function(files, rfp_file) {
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const reader = new FileReader();
    reader.onload = (function(file_store) {
       return function(e) {
        file_store.src = e.target.result;
      }; 
    })(rfp_file);

    reader.readAsDataURL(file);
  }
};

stickFn();
$(window).on('scroll', Foundation.util.throttle(stickFn, 500, 'linear')); // Start Slick Carousel

// Homepage Scroll
$('#arrow_down').on('click', function (event) {
  var divPosition = $('#second').offset().top;
  $('html,body').animate({
    scrollTop: divPosition - 80
  }, 'slow');
  return false;
}); 

// Off-canvas and sticky
var scrollPosRef = 0;
$(window).on('scroll', function () {
  $('.module-nav.sticky-top').css({
    'top': $(window).scrollTop()
  });
});
$(document).on('opened.zf.offcanvas', function () {
  $('.module-nav.sticky-top').css({
    'top': scrollPosRef + 'px'
  });
  $('.wrapper').css('paddingTop', scrollPosRef + 'px\0');
  $(window).scrollTop(scrollPosRef);
  $(window).on('scroll', function () {
    $('.wrapper').stop().animate({
      "paddingTop": $(window).scrollTop() + "px"
    }, "slow");

    if ($('div').closest('.is-open-right').length >= 1) {
      $('.module-nav.sticky-top').css({
        'top': $(window).scrollTop(),
        'width': '100%'
      });
    }
  });
});
$(document).on('turbolinks:load', function () {
  $('*[data-toggle]').on('click', function () {
    scrollPosRef = $(document).scrollTop();
  });

  $(globalVar.carouselEle).slick({
    centerMode: true,
    centerPadding: '5px',
    slidesToShow: 1,
    speed: 800,
    prevArrow: '<button class="slick-arrow slick-prev module-arrow"><div class="line left"></div><div class="line right"></div></button>',
    nextArrow: '<button class="slick-arrow slick-next module-arrow"><div class="line left"></div><div class="line right"></div></button>',
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 1
      }
    }]
  });
  $(globalVar.carouselEle).find('.slick-active').prev().addClass('module-carousel__item_sides');
  $(globalVar.carouselEle).find('.slick-active').next().addClass('module-carousel__item_sides');
  $(globalVar.carouselInfobox).eq(0).addClass('active');
  $(globalVar.carouselEle).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    $(globalVar.carouselEle).find(globalVar.carouselItem).removeClass('module-carousel__item_sides');
    $(globalVar.carouselInfobox).removeClass('active');
    $(slick.$slides[nextSlide]).prev().addClass('module-carousel__item_sides');
    $(slick.$slides[nextSlide]).next().addClass('module-carousel__item_sides');
    $(globalVar.carouselInfobox).eq($(slick.$slides[nextSlide]).data('slick-index')).addClass('active');
  });

  Foundation.addToJquery($);
  $(document).foundation();

  // uploadFiles
  $('input[name="rfp_upload"]').on('change', function (e) {
    e.preventDefault();

    globalVar.rfp_uploaded = {};
    uploadFiles(e.target.files, globalVar.rfp_uploaded);
  });

  // Submit contact
  $('#form_contact').on('submit', function (e) {
    e.preventDefault();

    if (globalVar.formSumitted) {
      // console.log({formSumitted: globalVar.formSumitted});
      return false;
    }

    // Form is passed validation
    globalVar.formSumitted = true;

    // Disable submit button
    var submitBtn = $('#form_contact #submit');
    submitBtn.attr('disabled', true).val('Please wait...');

    $.post(
      '/contact',
      {
        // list_id: $('.subscribe-right-rail-component').data('listId'),
        authenticity_token: $('meta[name="csrf-token"]').attr('content'),
        email: $('input[name="email"]').val(),
        company: $('input[name="company"]').val(),
        first_name: $('input[name="first_name"]').val(),
        last_name: $('input[name="last_name"]').val(),
        phone: $('input[name="phone"]').val(), // Phone
        country: $('input[name="country"]').val(), // Address
        desired_services: $('input[name="00N1N00000OrUkk"]').val(), // 00N1N00000OrUkk - Desired_Services__c
        how_did_you_hear_about_us: $('input[name="00N1N00000OrUkp"]').val(), // 00N1N00000OrUkp - How_did_you_hear_about_us__c
        rfp_upload: globalVar.rfp_uploaded
      },
      function(data)  {
        const leadId = data && data.leadId;
        if (leadId) {
          $('.main-content > .contact-form').hide();
          $('.main-content > .thank-you').fadeIn();
          window.leadId = leadId;
        }
      })
      .always(function() {
        globalVar.formSumitted = false;
      });
  });

});
