$(document).ready(function() { 
  $('input').on('keyup', function() {
    $('.error_message').hide();
    $(this).removeClass('red_border');
  });
  
  $('.submit-button').on('click', function (event) {
    var email = $('#email').val();
    var phone = $('#phone').val();
    var hasError = false;
    var email_regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i;
    var phone_regex = /^[0-9]+$/;

    $('.required').removeClass('red_border');
    $('.error_message_mandatory, .error_message').hide();

    function showError(selector) {
      $(selector).focus().addClass('red_border');
      $(selector).next('.error_message').show();
      hasError = true;
    }

    $('.required').each(function() {
      if ($(this).val().length == 0) {
        $('.error_message_mandatory').show();
        showError(this);
      }
    });

    if (!email.match(email_regex)) {
      showError('#email');
    }

    if (!phone.match(phone_regex)) {
      showError('#phone');
    }

    if (hasError == true) {
      return false;
    } else {
      $('#form_contact').submit();
      return true;
    }
  });
}); 